@import 'vars';
@import '~react-perfect-scrollbar/dist/css/styles.css';

.ant-picker-cell-disabled {
  pointer-events: auto !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background-color: var(--rate-star-color);
}
.hidden-class {
  display: none;
}
.ant-table-ping-right:not(.ant-table-has-fix-right)
  .ant-table-container::after {
  box-shadow: none;
}
.ant-badge-status-processing::after {
  border: 1px solid #52c41a;
}
.ant-legacy-form-item-label > label,
.ant-legacy-form-extra,
.ant-legacy-form-explain {
  color: @text-color;
}

.ant-legacy-form-item-children > .ant-form-text {
  color: @text-color;
}

.ant-legacy-form-item-control.has-error .ant-input,
.ant-legacy-form-item-control.has-error .ant-input:hover {
  background-color: @component-background !important;
}

.ant-tabs-content > .ant-tabs-tabpane {
  background: @component-background;
  padding: 16px;
}

.ant-tabs-content {
  min-height: 60px;
  margin-top: -16px;
}

@media (max-width: 480px) {
  .ant-picker-datetime-panel {
    flex-direction: column !important;
  }

  .ant-picker-dropdown {
    top: 200px !important;
  }
}
.highcharts-container rect.highcharts-background {
  fill: var(--component-background);
}

.highcharts-text-outline {
  stroke: transparent;
}
.highcharts-subtitle,
.highcharts-credits,
.highcharts-axis-title {
  fill-opacity: 0.7;
}
.highcharts-grid-line {
  stroke: var(--text-color);
  stroke-opacity: 0.1;
}
.highcharts-tooltip-box {
  fill: rgba(0, 0, 0, 0.85);
}
.highcharts-tooltip {
  color: var(--text-color) !important;
}
.highcharts-column-series rect.highcharts-point {
  stroke: var(--component-background);
}

.highcharts-pie-series .highcharts-legend-item text {
  fill: var(--text-color);
  color: var(--text-color);
}

.highcharts-pie-series text,
.highcharts-pie-series tspan {
  fill: var(--text-color);
}

.highcharts-gauge-series span {
  color: var(--text-color);
}

.highcharts-solidgauge-series span {
  color: var(--text-color);
}

.highcharts-xaxis-labels text,
.highcharts-xaxis-labels tspan {
  fill: var(--text-color) !important;
}

.highcharts-title {
  color: var(--text-color) !important;
  fill: var(--text-color) !important;
}

.highcharts-legend .highcharts-legend-item text,
.highcharts-legend .highcharts-legend-item tspan {
  fill: var(--text-color);
}

.highcharts-boxplot-box {
  fill: var(--layout-body-background);
}

.highcharts-series-1.highcharts-color-1 path:first-of-type {
  stroke: var(--text-color);
}

.rc-virtual-list-scrollbar {
  display: block !important;
}

.fullscreen.fullscreen-enabled {
  background: var(--layout-body-background);
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@root-entry-name: default;