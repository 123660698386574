@import '~antd/lib/style/themes/default.less';

@select-item-selected-option-color: @primary-color;
@processing-color: @primary-color;
@select-item-selected-bg: @background-color-base;
@secondary-color: @primary-color;
@skeleton-color: @primary-color;
@btn-primary-bg: @primary-color;
@border-radius-base: 4px;
@highcharts-second-color: #666666;
@highcharts-data-labels-color: #000000;

:root {
  --PC: @primary-color;
  --component-background: @component-background;
  --layout-header-background: @layout-header-background;
  --layout-body-background: @layout-body-background;
  --text-color: @text-color;
  --body-background: @body-background;
  --border-color-base: @border-color-base;
  --border-color-split: @border-color-split;
  --alert-success-bg-color: @alert-success-bg-color;
  --alert-error-bg-color: @alert-error-bg-color;
  --rate-star-color: @rate-star-color;
  --active-background: @primary-2;
  --green-success: #52c41a;
  --red-error: #ff4d4f;
  --red-error-bg-color: #ee9f9f;
  --gold-warning:#faad14;
  --orange-in-progress: #ffc069;
  --neutral-gray: #8c8c8c;
  --daybreak-blue: #1890ff;
  --polar-green: #52c41a;
  --highcharts-second-color: @highcharts-second-color;
  --highcharts-data-labels-color: @highcharts-data-labels-color;
}
